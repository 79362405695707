/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import './index.css';

import CarLogoImg from "../../../assets/images/car-logo.png";
import CarLogoDarkImg from "../../../assets/images/car-logo-dark.png";

interface ILogoProps {
  color?: "white" | "dark";
  bgColor?: "white" | "dark";
}

const LogoContainer = styled.div`
  ${tw`

    flex
        flex-col
    items-center
  `};
`;

const LogoText = styled.div`
  ${tw`
    text-xl
    md:text-red-600
    [font-size: large]
    font-bold
    [font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ]
    [padding-top: ch]
    m-1
    `};
` ;

const Image = styled.div`
  width: auto;
  ${tw`h-9 md:h-9`};

  img {
    width:auto;
    height: 200%;
    
  }
`;

export function Logo(props: ILogoProps) {
  const { color, bgColor } = props;

  return (
    <LogoContainer className="logocontainer">
      <Image className="logoimage">
        <img src={bgColor === "dark" ? CarLogoDarkImg : CarLogoImg} />
      </Image>
      <LogoText className="logotext"> HireacarPK</LogoText >
    </LogoContainer>
  );
}
