import React from "react";
import { Link } from "react-router-dom";


function Contact() {


    return (
        <div className="App">
            <header className="App-header">
                <p>Contact</p>

                <Link to="/">go back</Link>
            </header>
        </div>
    );
}

export default Contact;