import React from "react";
import './index.css'
import Google from "../../../assets/images/google.png";

function AuthPage() {


  const google = () => {
    window.open("http://localhost:5000/auth/google", "_self");
  };



  return (

    <div className="login">


      <form className="authform">
        <h1 className="loginTitle">Choose a Login Method</h1>

        <div className="left">
          <div className="loginButton google" onClick={google}>
            <img src={Google} alt="" className="icon" />
            Google
          </div>


        </div>
        <div className="center">
          <div className="line" />
          <div className="or">OR</div>
        </div>
        <div className="right">
          <div className="form-control">
            <label htmlFor="email">Email </label>
            <input type="email" id="email" />
          </div><div
            className="form-control">
            <label htmlFor="password">Password </label>
            <input type="password" id="password" />
          </div><div className="form-actions">
            <button type="submit">Submit</button>
            <button type="button">Signup</button>
          </div>
        </div>

        <footer />
      </form>
    </div >
  );
}

export default AuthPage;