import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { BookCard } from "../../components/bookCard";
import { Marginer } from "../../components/marginer";
import { BookingSteps } from "./bookingSteps";
import { TopCars } from "./topCars";
import { TopSection } from "./topSection";
import CarForm from "../CarForm";

const PageContainer = styled.div`
  ${tw`
    flex
    flex-col
    [font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif]
    w-full
    h-full
    items-center
   overflow-x-hidden

  `}
`;

function HomePage() {
  return (
    <PageContainer>
      <Marginer direction="vertical" margin="8em" />
      <BookCard />
      <TopCars />
      <TopCars />
      <TopCars />
      <TopCars />
      <TopCars />
    </PageContainer>


  );
}

export default HomePage;
