import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { slide as Menu } from "react-burger-menu";
import { useMediaQuery } from "react-responsive";
import { SCREENS } from "../responsive";
import { Link, NavLink } from "react-router-dom";
import menuStyles from "./menuStyles";

const ListContainer = styled.div`


  ${tw`
space-x-20
m-auto
mt-1.5
bg-center	
no-underline
 `};
`;








export function Navitem() {
  const isMobile = useMediaQuery({ maxWidth: SCREENS.sm });

  if (isMobile)
    return (
      <Menu left styles={menuStyles}>
        <ListContainer>
          <NavLink
            to="/"> Home
          </NavLink>
          <NavLink
            to="/AuthPage"> Login
          </NavLink>
          <NavLink
            to="/CarForm">Services
          </NavLink>
          <NavLink
            to="/Contact"> contactus
          </NavLink>
        </ListContainer>

      </Menu>
    );

  return (

    <ListContainer className="menu">
      <NavLink
        to="/"> Home
      </NavLink>
      <NavLink
        to="/AuthPage"> Login
      </NavLink>
      <NavLink
        to="/CarForm">Services
      </NavLink>
      <NavLink
        to="/Contact"> contactus
      </NavLink>
    </ListContainer>
  );
}
