import { BrowserRouter, Routes, Route, Navigate, Link } from 'react-router-dom';
import React from 'react';
import AuthPage from "./app/containers/AuthPage";
import CarForm from './app/containers/CarForm';
import HomePage from './app/containers/HomePage';
import Contact from './app/containers/Contact';
import { useEffect, useState } from "react";
import { HttpLink } from '@apollo/client';
import { addPath } from 'graphql/jsutils/Path';
import { Logo } from './app/components/logo';
import { Navitem } from './app/components/navitem/navItem'
import Navbar from './app/components/navbar/Navbar';

export function App() {

    const [user, setUser] = useState(null);

    useEffect(() => {
        const getUser = () => {
            fetch("http://localhost:5000/auth/login/success", {
                method: "get",
                credentials: "include",
            })
                .then((response) => {
                    if (response.status === 200) return response.json();
                    throw new Error("authentication has been failed!");
                })
                .then((resObject) => {
                    setUser(resObject.user);
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        getUser();
    }, []);


    return (


        <BrowserRouter>
            <div>
                <Navbar user={user} />
                <Logo />
                <Navitem />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/AuthPage" element={user ? <Navigate to="/" /> : <AuthPage />} />

                    {/* <Route path="/CarForm" element={user ? <CarForm /> : <Navigate to="/AuthPage" />} /> */}
                    <Route path="/Contact" element={user ? <Contact /> : <Navigate to="/AuthPage" />} />
                </Routes>
            </div>
        </BrowserRouter>
    );

}
export default App;